import apiClient from './api';

export default {
  fetchSolicitudEmpleo() {
    return apiClient.get('/empleos?populate[0]=DatosGenerales.ZonaMic&populate[1]=DatosGenerales.Departamento&populate[2]=DatosGenerales.Municipio&populate[3]=DatosGenerales.Localidad&populate[4]=NivelAcademico&populate[5]=EmpleosAnteriores&populate[6]=Habilidades&populate[7]=PersonasConocen&populate[8]=Familiares');
  },

  fecthSolicitudesID(){
    return apiClient.get('/empleos');
  },

  // Metodos GET para cargar los datos en los combobox en Marimonios
  getCiudades() {
    return apiClient.get('/municipios');
  },
  getDepartamentos() {
    return apiClient.get('/departamentos');
  },

  // Metodos POST para guardar los datos de Matrimonios
  postSolicitudEmpleo(data) {
    return apiClient.post('/empleos', { data }).then(response => response.data);;
  },

  //Metodos PUT para actualizar los datos de Matrimonios
  putSolicitudEmpleo(id, data) {
    return apiClient.put(`/empleos/${id}`, { data });
  },

  getEmpleosporDepartamento(fecha) {
    // Crear un objeto de fecha en la zona horaria local
    // const localDate = new Date(fecha);

    // // Obtener las versiones UTC de las horas
    // const fechaInicioUTC = new Date(localDate.setUTCHours(0, 0, 0, 0)).toISOString();
    // const fechaFinUTC = new Date(localDate.setUTCHours(23, 59, 59, 999)).toISOString();

    const fechaInicio = `${fecha}T00:00:00`;
    const fechaFin = `${fecha}T23:59:59`;

    return apiClient.get('/empleos/dates', {
      params: {
        'filters[createdAt][$gte]': fechaInicio,
        'filters[createdAt][$lte]': fechaFin,
        'populate': ['Departamento'],
      },
    });
  },
}
