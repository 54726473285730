<template>
  <div class="main-content">
    <div>
      <!-- Información y controles superiores -->
      <div class="controls-wrapper mb-4" style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <!-- Selector de registros por página -->
          <div class="records-per-page">
            <label class="mr-2">Mostrar:</label>
            <select v-model="perPage" class="form-control form-control-sm d-inline-block" style="width: auto">
              <option v-for="n in [5, 10, 25, 50, 100]" :key="n" :value="n">{{ n }}</option>
            </select>
            <span class="ml-2">registros</span>
          </div>
        </div>
      </div>
      <table class="table" :class="tableClass" style="table-layout: auto;">
        <thead>
          <slot name="columns">
            <th v-for="column in filteredColumns" :key="column">{{ column }}</th>
          </slot>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedFilteredData" :key="index">
            <td v-for="(column, columnIndex) in filteredColumns" :key="columnIndex">
              <template v-if="column === 'id'">
                {{ item.id }}
              </template>
              <template v-else>
                <template v-if="getItemValue(item, column) !== undefined">
                  {{ getItemValue(item, column) }}
                </template>
                <template v-else>
                  N/A
                </template>
              </template>
            </td>
            <td>
              <button @click="detailsItem(item)" class="btn btn-sm btn-success mr-2">
                <i class="ti-eye button-icon"></i>
                <span class="button-text">Ver Detalles</span>
              </button>
              <button @click="editItem(item)" class="btn btn-sm btn-info">
                <i class="ti-pencil button-icon"></i>
                <span class="button-text">Editar</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row" style="margin: 20px;">
        <div class="col text-left" style="padding: 10px;">
          Mostrando del {{ startRecord }} al {{ endRecord }} de {{ filteredData.length }} registros
        </div>
        <div class="col text-right">
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-end mb-0">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
              </li>
              <li class="page-item" v-for="page in totalPagesArray" :key="page" :class="{ active: currentPage === page }">
                <a class="page-link" href="#" @click.prevent="setPage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" href="#" @click.prevent="nextPage">Siguiente</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Modal :showModal="showModal" :details="selectedDetail || {}" @close="closeDetailModal" />
    </div>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import EditarMembresia from '@/pages/EditarMembresias.vue';

export default {
  name: "Membresias",
  components: {
    Modal
  },
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "hover",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tableClass: `table-${this.type}`,
      showModal: false,
      selectedDetail: {},
      columnsToShow: [
        "id",
        "Numero Membresia",
        "Nombre Completo",
        "Numero Identidad",
      ]

    };
  },
  computed: {
    filteredData() {
      if (!this.searchQuery) {
        return this.data;
      }

      const searchTerm = this.searchQuery.toLowerCase();
      return this.data.filter(item => {
        return this.columnsToShow.some(column => {
          const value = this.getItemValue(item, column);
          return value && String(value).toLowerCase().includes(searchTerm);
        });
      });
    },
    paginatedFilteredData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredData.slice(start, end);
    },
    totalRecords() {
      return this.data.length;
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.perPage);
    },
    totalPagesArray() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      if (totalPages <= 3) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }
      if (currentPage <= 2) {
        return [1, 2, 3, '...', totalPages];
      }
      if (currentPage >= totalPages - 1) {
        return [1, '...', totalPages - 2, totalPages - 1, totalPages];
      }
      return [1, '...', currentPage, '...', totalPages];
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },
    startRecord() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endRecord() {
      return Math.min(this.currentPage * this.perPage, this.filteredData.length);
    },
    filteredColumns() {
      return this.columns.filter(column => this.columnsToShow.includes(column));
    }
  },
  watch: {
    searchQuery() {
      this.currentPage = 1; // Reset to first page when searching
    }
  },
  methods: {

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setPage(page) {
      if (page !== '...') {
        this.currentPage = page;
      }
    },
    getItemValue(item, column) {
      const columnKey = column.replace(/\s+/g, ''); // Eliminar espacios para coincidir con las claves en item.attributes
      if (item[columnKey] !== undefined) {
        return item[columnKey];
      }
      if (item.attributes && item.attributes[columnKey] !== undefined) {
        return item.attributes[columnKey];
      }
      return undefined;
    },
    detailsItem(item) {
      this.showDetailModal(item);
    },
    editItem(item) {
      this.$router.push({ name: 'Editar Membresias', params: { id: item.id } });
    },
    showDetailModal(item) {
      this.selectedDetail = { ...item };
      this.showModal = true;
    },
    closeDetailModal() {
      this.selectedDetail = {};
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.main-content {
  margin-bottom: 20px; /* Adjust this value based on the height of your footer */
}
.table {
  width: 100%;
  height: 100%;
  /* background-color: #f8f9fa; Ajusta el color de fondo según tus necesidades */
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Ajusta el ancho máximo según tus necesidades */
}
.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

/* Hide icons by default for desktop views */
.button-icon {
  display: none !important;
}

/* Mobile devices */
@media (max-width: 768px) {
  .button-icon {
    display: inline-block !important; /* Show icons on mobile */
  }
  .col.text-right {
    align-items: center !important;
  }
  .col.text-left {
    text-align: center !important;
  }
  .button-text {
    display: none !important; /* Optionally hide the text on mobile if desired */
  }
  .pagination {
    justify-content: center !important;
  }
}
.pagination {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-item {
  margin: 0 0.125rem;
}

.page-link {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  color: #6c757d;
  background-color: white;
  transition: all 0.2s ease;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  font-weight: 500;
}

.page-link:hover {
  background-color: var(--hover-color);
  border-color: var(--border-color);
  color: #007bff;
}
.text-left {
  color: #6c757d;
  font-size: 0.9rem;
}
</style>






