<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel" />
        <sidebar-link to="/membresias" name="Membresias" icon="ti-user" />
        <sidebar-link to="/matrimonios" name="Matrimonios" icon="ti-heart" />
        <sidebar-link to="/solicitud-empleo" name="Solicitudes de Empleo" icon="ti-briefcase" />
        <sidebar-link to="/descargas" name="Descargas" icon="ti-download" />
      </template>
      <mobile-menu>
        <!-- <li class="nav-item">
          <a class="nav-link">
            <i class="ti-stats-up"></i>
            <p>Estadisticas</p>
          </a>
        </li> -->
        <!-- <drop-down
          class="nav-item"
          title="Notificaciones"
          title-classes="nav-link"
          icon="ti-bell"
        >
          <a class="dropdown-item">Notification</a>
          <a class="dropdown-item">Notification 2</a>
          <a class="dropdown-item">Notification 3</a>
          <a class="dropdown-item">Notification 4</a>
          <a class="dropdown-item">Another notification</a>
        </drop-down> -->
        <li class="nav-item">
            <a href="#" class="nav-link" @click="logout">
              <i class="ti-shift-right"></i>
              <p>Cerrar Sesion</p>
            </a>
          </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  methods: {
    logout() {
      // Ask the user for confirmation
      if (confirm('¿Desea abandonar esta sesión?')) {
        // Remove the token
        sessionStorage.removeItem('jwt');
        console.log('JWT token removed:', !sessionStorage.getItem('jwt'));

        // Redirect to the login page
        this.$router.push({ name: '/login' });
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
