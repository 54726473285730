<template>
  <div>
    <!-- Stats cards -->
    <div class="row">
      <div class="col-md-6 col-xl-4" v-for="stats in statsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{ stats.title }}</p>
            {{ stats.value }}
          </div>
          <div class="stats" slot="footer">
            <a :href="stats.link">
              <i :class="stats.footerIcon"></i> {{ stats.footerText }}
            </a>
          </div>
        </stats-card>
      </div>
    </div>

    <!-- Selector de fecha con estilos y botón -->
    <div class="row">
  <div class="col-12 col-md-4">
    <div class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center gap-2">
      <input type="date" v-model="selectedDate" class="form-control" id="fecha" />
      <button @click="fetchData" class="btn btn-default">Buscar</button>
    </div>
  </div>
</div>

    <!-- Título de la tabla con última actualización -->
    <div class="row">
      <div class="col-12 col-lg-4">
  <card-formularios :title="table.title" :subTitle="table.subTitle">
    <div class="mb-2">
      <small>Última actualización: {{ lastUpdateTime }}</small>
    </div>
    <div class="table-container"> <!-- Nuevo contenedor para el scroll -->
      <div class="table-responsive">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Departamento</th>
              <th class="text-end" style="min-width: 120px">Total Solicitudes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(empleo, index) in empleosPorDepartamento" :key="index">
              <td class="text-break">{{ empleo.departamento }}</td>
              <td class="text-end">{{ empleo.totalEmpleos }}</td>
            </tr>
            <tr>
              <td><strong>Total</strong></td>
              <td class="text-end">
                <strong>
                  {{ empleosPorDepartamento.reduce((acc, empleo) => acc + empleo.totalEmpleos, 0) }}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card-formularios>
</div>

      <!-- Charts -->
      <!-- <div class="col-md-8 col-12">
        <chart-card
          title="Solicitudes por Departamento"
          sub-title="Total de solicitudes de empleo"
          :chart-data="activityChart.data"
          :chart-options="activityChart.options"
        >
          <span slot="footer">
            <i class="ti-check"></i> Información basada en el total de registros
          </span>
        </chart-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { StatsCard, ChartCard } from "@/components/index";
import CardFormularios from "@/components/Cards/Card.vue";
import solicitudesService from "@/services/solicitud_empleo_service.js";
import membresiasService from '@/services/membresias_service';
import matrimoniosService from '@/services/matrimonios_service';

export default {
  components: {
    StatsCard,
    CardFormularios,
    ChartCard,
  },

  data() {
    return {
      sselectedDate: new Date().toISOString().split('T')[0], // Establece la fecha actual por defecto
      table: {
        title: "Solicitudes de Empleo por Departamento",
        subTitle: "HIGUERTROPIC HONDURAS, S. DE. R.L.",
      },
      empleosPorDepartamento: [],
      lastUpdateTime: "", // Almacena la fecha y hora de la última actualización
      statsCards: [
        {
          type: "warning",
          icon: "ti-user",
          title: "Membresias Activas",
          value: 0,
          footerText: "Ingresar Membresia",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-membresia',
        },
        {
          type: "danger",
          icon: "ti-heart",
          title: "Matrimonios Activos",
          value: 0,
          footerText: "Ingresar Matrimonio",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-matrimonio',
        },
        {
          type: "success",
          icon: "ti-briefcase",
          title: "Solicitudes Activas",
          value: 0,
          footerText: "Ingresar Solicitud",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-solicitud-empleo',
        }
      ],
      activityChart: {
        data: {
          labels: [
            "AT", "CL", "CM", "CP", "CR", "CH", "EP", "FM", "GD",
            "IN", "IB", "LP", "LE", "OC", "OL", "SB", "VA", "YO"
          ],
          series: [
            [] // Esta serie se llenará con los datos de solicitudes por departamento
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: "245px",
        },
      },
    };
  },

  mounted() {
    this.fetchEmpleosPorDepartamento();
    // Para total de membresias
    this.fetchAndUpdateCard(membresiasService.fecthMembresiasID, 0);

    // Para total de matrimonios
    this.fetchAndUpdateCard(matrimoniosService.fecthMatrimoniosID, 1);

    // Para total de solicitudes
    this.fetchAndUpdateCard(solicitudesService.fecthSolicitudesID, 2);

     // Establecer la fecha actual como predeterminada
     const today = new Date();
    this.selectedDate = today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    this.fetchEmpleosPorDepartamento(); // Cargar datos para la fecha actual
  },

  methods: {
    async fetchAndUpdateCard(serviceMethod, cardIndex) {
      try {
        const response = await serviceMethod();
        const totalItems = response.data.data.length;
        this.animateCounter(cardIndex, totalItems); // Iniciar la animación del contador
      } catch (error) {
        console.error(`Error fetching data for card ${cardIndex}:`, error);
        this.updateStatsCard(cardIndex, 0); // Mostrar 0 en caso de error
      }
    },

    updateStatsCard(cardIndex, value) {
      // Usar Vue.set para garantizar reactividad
      this.$set(this.statsCards[cardIndex], 'value', value);
    },

    animateCounter(cardIndex, finalValue) {
      let currentValue = 0;
      const increment = Math.ceil(finalValue / 100); // Ajustar incremento si es necesario
      const interval = setInterval(() => {
        if (currentValue < finalValue) {
          currentValue += increment;
          if (currentValue > finalValue) currentValue = finalValue; // Evitar que exceda el valor final
          this.updateStatsCard(cardIndex, currentValue);
        } else {
          clearInterval(interval); // Detener el intervalo cuando se alcance el valor final
        }
      }, 50); // Ajustar velocidad según sea necesario
    },

    //METODO QUE ME DEVUELVE TODOS LOS DEPARTAMENTOS
//     async fetchEmpleosPorDepartamento() {
//   try {
//     const responseEmpleos = await solicitudesService.getEmpleosporDepartamento(this.selectedDate);
//     console.log('responseEmpleos:', responseEmpleos);
//     const empleos = responseEmpleos.data;

//     const departamentos = {
//       "Atlántida": "AT",
//       "Colón": "CL",
//       "Comayagua": "CM",
//       "Copán": "CP",
//       "Cortés": "CR",
//       "Choluteca": "CH",
//       "El Paraíso": "EP",
//       "Francisco Morazán": "FM",
//       "Gracias a Dios": "GD",
//       "Intibucá": "IN",
//       "Islas de la Bahía": "IB",
//       "La Paz": "LP",
//       "Lempira": "LE",
//       "Ocotepeque": "OC",
//       "Olancho": "OL",
//       "Santa Bárbara": "SB",
//       "Valle": "VA",
//       "Yoro": "YO",
//     };

//     // Inicializar el resumen de solicitudes por departamento
//     const resumen = {
//       "AT": 0, "CL": 0, "CM": 0, "CP": 0, "CR": 0, "CH": 0, "EP": 0,
//       "FM": 0, "GD": 0, "IN": 0, "IB": 0, "LP": 0, "LE": 0, "OC": 0,
//       "OL": 0, "SB": 0, "VA": 0, "YO": 0
//     };

//     empleos.forEach((empleo) => {
//       if (empleo.Departamento && empleo.Departamento.Nombre) {
//         const abreviacion = departamentos[empleo.Departamento.Nombre] || null;
//         if (abreviacion) {
//           resumen[abreviacion]++;
//         }
//       }
//     });

//     this.empleosPorDepartamento = Object.keys(resumen).map(abreviacion => ({
//       departamento: Object.keys(departamentos).find(key => departamentos[key] === abreviacion),
//       totalEmpleos: resumen[abreviacion],
//     }));

//     // Actualizar la serie de solicitudes en el gráfico
//     this.activityChart.data.series[0] = Object.values(resumen);
//     this.lastUpdateTime = new Date().toLocaleString(); // Actualizar la hora de la última actualización
//   } catch (error) {
//     console.error("Error fetching empleos por departamento:", error);
//   }
// },

async fetchEmpleosPorDepartamento() {
  try {
    const responseEmpleos = await solicitudesService.getEmpleosporDepartamento(this.selectedDate);
    console.log('responseEmpleos:', responseEmpleos);
    const empleos = responseEmpleos.data;

    const departamentos = {
      "Atlántida": "AT",
      "Colón": "CL",
      "Comayagua": "CM",
      "Copán": "CP",
      "Cortés": "CR",
      "Choluteca": "CH",
      "El Paraíso": "EP",
      "Francisco Morazán": "FM",
      "Gracias a Dios": "GD",
      "Intibucá": "IN",
      "Islas de la Bahía": "IB",
      "La Paz": "LP",
      "Lempira": "LE",
      "Ocotepeque": "OC",
      "Olancho": "OL",
      "Santa Bárbara": "SB",
      "Valle": "VA",
      "Yoro": "YO",
    };

    // Inicializar el resumen de solicitudes por departamento
    const resumen = {
      "AT": 0, "CL": 0, "CM": 0, "CP": 0, "CR": 0, "CH": 0, "EP": 0,
      "FM": 0, "GD": 0, "IN": 0, "IB": 0, "LP": 0, "LE": 0, "OC": 0,
      "OL": 0, "SB": 0, "VA": 0, "YO": 0
    };

    empleos.forEach((empleo) => {
      if (empleo.Departamento && empleo.Departamento.Nombre) {
        const abreviacion = departamentos[empleo.Departamento.Nombre] || null;
        if (abreviacion) {
          resumen[abreviacion]++;
        }
      }
    });

    this.empleosPorDepartamento = Object.keys(resumen)
  .map(abreviacion => ({
    departamento: Object.keys(departamentos).find(key => departamentos[key] === abreviacion),
    totalEmpleos: resumen[abreviacion],
  }))
  .filter(empleo => empleo.totalEmpleos > 0); // Filtra los departamentos con totalEmpleos > 0


    // Actualizar la serie de solicitudes en el gráfico
    this.activityChart.data.series[0] = Object.values(resumen);
    this.lastUpdateTime = new Date().toLocaleString(); // Actualizar la hora de la última actualización
  } catch (error) {
    console.error("Error fetching empleos por departamento:", error);
  }
},
fetchData() {
  // Puedes realizar validaciones y luego llamar a fetchEmpleosPorDepartamento si es necesario
  this.fetchEmpleosPorDepartamento();
},

    fetchData() {
      // Puedes realizar validaciones y luego llamar a fetchEmpleosPorDepartamento si es necesario
      this.fetchEmpleosPorDepartamento();
    },
  },
};
</script>


<style>
.table-container {
  max-height: 400px; /* Ajusta la altura máxima del contenedor */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  overflow-x: hidden; /* Desactiva el desplazamiento horizontal */
}

.table-responsive {
  width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
  overflow-x: hidden; /* Bloquea el desplazamiento horizontal */
}

.table {
  width: 100%; /* Hace que la tabla ocupe todo el ancho disponible */
  table-layout: fixed; /* Hace que las celdas se ajusten al ancho disponible */
}

.table th, .table td {
  word-wrap: break-word; /* Ajusta palabras largas automáticamente */
  overflow: hidden; /* Oculta el contenido que se desborda */

}

@media (max-width: 768px) {
  .table-container {
    max-height: 300px; /* Ajusta la altura para pantallas más pequeñas */
  }
}


</style>




