<template>
  <div class="row">
    <div class="col-12">
      <card-formularios
        :title="table.title"
        :subTitle="table.subTitle"
        @search="handleSearch"
      >
        <div slot="raw-content" class="table-scroll-container">
          <div class="table-outer-wrapper">
            <div class="table-inner-wrapper">
              <!-- Loading Skeleton -->
              <div v-if="isLoading" class="skeleton-wrapper">
                <div class="skeleton-table">
                  <div class="skeleton-header">
                    <div class="skeleton-cell" v-for="n in 4" :key="'header-'+n"></div>
                  </div>
                  <div class="skeleton-row" v-for="row in 5" :key="'row-'+row">
                    <div class="skeleton-cell" v-for="cell in 4" :key="'cell-'+cell"></div>
                  </div>
                </div>
              </div>

              <!-- Actual Table -->
              <membresias-table
                v-else
                type="hover"
                :title="table.title"
                :sub-title="table.subTitle"
                :data="table.data"
                :columns="table.columns"
                :search-query="searchQuery"
              >
              </membresias-table>
            </div>
          </div>
        </div>
      </card-formularios>
    </div>
  </div>
</template>

<script>
import { MembresiasTable } from "@/components";
import CardFormularios from '@/components/Cards/CardMembresias.vue';
import membresiasService from '@/services/membresias_service';

const tableColumns = [
  "id",
  "Numero Membresia",
  "Nombre Completo",
  "Numero Identidad",

  // Add additional columns as needed
];

export default {
  components: {
    MembresiasTable,
    CardFormularios,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: '',
      table: {
        title: "Tabla Registro Membresias",
        subTitle: "Datos activos de miembros",
        columns: tableColumns,
        data: []
      },
      datosgenerales: [],
      membresias: [],
      datosprofesionales: [],
      datosempresariales: [],
      datosespirituales: [],
    };
  },
  methods: {
    handleSearch(query) {
      this.searchQuery = query;
    },
  },

async mounted() {
    try {
      this.isLoading = true;
        const response = await membresiasService.fetchMembresias();
        const membresias = response.data.data;
        console.log('membresias en el sistema:', membresias);

        // Procesa los datos recibidos para extraer lo que necesitas
        this.table.data = membresias.map(membresia => {
            return {
              id: membresia.id,
              NumeroMembresia: membresia.attributes.Ubicacion.NumeroMembresia,
              ZonaMic: membresia.attributes.Ubicacion.ZonaMic?.data?.attributes?.Nombre,
              Localidad: membresia.attributes.Ubicacion.Localidad?.data?.attributes?.Nombre,
              Departamento: membresia.attributes.Ubicacion.Departamento?.data?.attributes?.Nombre,
              Municipio: membresia.attributes.Ubicacion.Municipio?.data?.attributes?.Nombre,
              Zona: membresia.attributes.Ubicacion.Zona?.data?.attributes?.Nombre,

              NombreCompleto: membresia.attributes.DatosGenerales.NombreCompleto,
              NumeroIdentidad: membresia.attributes.DatosGenerales.NumeroIdentidad,
              EstadoCivil: membresia.attributes.DatosGenerales.EstadoCivil,
              NombreEsposa: membresia.attributes.DatosGenerales.NombreEsposa,
              Domicilio: membresia.attributes.DatosGenerales.Domicilio,
              TelefonoFijo: membresia.attributes.DatosGenerales.TelefonoFijo,
              CorreoElectronico: membresia.attributes.DatosGenerales.CorreoElectronico,
              Celular: membresia.attributes.DatosGenerales.Celular,
              IHSS: membresia.attributes.DatosGenerales.IHSS,
              TipoSangre: membresia.attributes.DatosGenerales.TipoSangre,
              FechaNacimiento: membresia.attributes.DatosGenerales.FechaNacimiento,

              Profesion: membresia.attributes.DatosProfesionales.Profesion,
              Primaria: membresia.attributes.DatosProfesionales.Primaria,
              Secundaria: membresia.attributes.DatosProfesionales.Secundaria,
              Superior: membresia.attributes.DatosProfesionales.Superior,
              Cursos: membresia.attributes.DatosProfesionales.Cursos,
              CargosPublicos: membresia.attributes.DatosProfesionales.CargosPublicos,

              NombreEmpresa: membresia.attributes.DatosEmpresariales.NombreEmpresa,
              Direccion: membresia.attributes.DatosEmpresariales.Direccion,
              Cargo: membresia.attributes.DatosEmpresariales.Cargo,
              ActividadPrincipal: membresia.attributes.DatosEmpresariales.ActividadPrincipal,
              Telefono: membresia.attributes.DatosEmpresariales.Telefono,

              FechaIngreso: membresia.attributes.DatosEspirituales.FechaIngreso,
              Cargos: membresia.attributes.DatosEspirituales.Cargos,
              CargoActual: membresia.attributes.DatosEspirituales.CargoActual,
              CargoPasado: membresia.attributes.DatosEspirituales.CargoPasado,
              MiembroActivo: membresia.attributes.DatosEspirituales.MiembroActivo,
              Seminario: membresia.attributes.DatosEspirituales.Seminario,
              MiembroInvitador: membresia.attributes.DatosEspirituales.MiembroInvitador,
              Nombre: membresia.attributes.DatosEspirituales.Nombre,
              PastorOSacerdote: membresia.attributes.DatosEspirituales.PastorOSacerdote,
              Celular: membresia.attributes.DatosEspirituales.Celular,
              CorreoElectronico: membresia.attributes.DatosEspirituales.CorreoElectronico,
              CargosIA: membresia.attributes.DatosEspirituales.CargosIA,
              TiempoServicio: membresia.attributes.DatosEspirituales.TiempoServicio,
              CargoActualIA: membresia.attributes.DatosEspirituales.CargoActualIA,
              CargoPasadoIA: membresia.attributes.DatosEspirituales.CargosPasadoIA,
              Test:  membresia.attributes.DatosEspirituales.Test?.data?.attributes?.Testimonio,
              Don:  membresia.attributes.DatosEspirituales.Don?.data?.attributes?.DonesEsprirituales,

              };



        });
        console.log('table.data:', this.table.data);

    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      // Añade un pequeño delay para hacer la transición más suave
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
},
};
</script>

<style scoped>
.table-scroll-container,
.table-outer-wrapper,
.table-inner-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
}

.table {
  width: 100%;
  table-layout: auto;
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.skeleton-wrapper {
  width: 100%;
  padding: 1rem;
}

.skeleton-table {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton-header,
.skeleton-row {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.skeleton-cell {
  flex: 1;
  height: 20px;
  margin: 0 1rem;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 768px) {
  .skeleton-cell {
    margin: 0 0.5rem;
  }

  .skeleton-wrapper {
    padding: 0.5rem;
  }
}

.membresias-table {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

