<template>
  <div class="card">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header row p-4" v-if="$slots.header || title">
      <!-- Title and Subtitle -->
      <div class="col-12 col-md-4">
        <slot name="header">
            <h4 class="card-title">{{ title }}</h4>
            <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
        </slot>
    </div>
    <!-- Button -->
    <div class="col-12 col-md-4 my-2 my-md-0 ml-auto"> <!-- ml-auto mueve el elemento a la derecha -->
        <button class="btn btn-success w-100" @click="saveFile">
            <i class="ti-download"></i> Descargar Archivo Excel
        </button>
    </div>
      <!-- Search Bar -->
      <!-- <div class="col-12 col-md-4">
        <input id="barraBusqueda" type="text" class="form-control w-100" placeholder="Buscar registros...">
      </div> -->
    </div>
     <!-- Mensaje de espera -->
    <div class="card-body" v-if="loading">
      <div class="alert alert-info" role="alert">
        <strong>¡Generando archivo Excel! Por favor, espere... </strong>
      </div>
    </div>
    <!-- Mensaje de éxito -->
    <div class="card-body" v-if="success">
      <div class="alert alert-success" role="alert">
        <strong>¡Archivo Excel generado exitosamente!</strong>
      </div>
    </div>

    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" v-if="$slots.footer">
      <hr />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import solicitudEmpleoService from "@/services/solicitud_empleo_service";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      loading: false,
      success: false,
      // Otros datos
    };
  },
  name: "card",
  props: {
    title: String,
    subTitle: String,
  },
  methods: {
    async saveFile() {
      this.loading = true;
      this.success = false;
      try {
        // Llama a tu API
        const response = await solicitudEmpleoService.fetchSolicitudEmpleo();

        // Ajuste para acceder al array correcto
        const dataArray = response.data.data; // Accede a response.data.data si 'data' contiene el array

        console.log("Datos de la API:", dataArray);

        // Mapear los datos directamente
        const excelData = dataArray.map((item) => {
          const datosGenerales = item.attributes?.DatosGenerales || {};
          const nivelAcademico = item.attributes?.NivelAcademico || {};
          const empleosAnteriores = item.attributes?.EmpleosAnteriores || {};
          const habilidades = item.attributes?.Habilidades || {};
          const personasConocen = item.attributes?.PersonasConocen || {};
          const familiares = item.attributes?.Familiares || {};

          return {
            // Datos Generales
            id: item.id|| "",
            Fecha: datosGenerales.Fecha || "",
            ZonaMic: datosGenerales.ZonaMic || "",
            Departamento: datosGenerales.Departamento || "",
            Municipio: datosGenerales.Municipio || "",
            Localidad: datosGenerales.Localidad || "",
            Nombre: datosGenerales.Nombre || "",
            Identidad: datosGenerales.Identidad || "",
            Sueldo: datosGenerales.Sueldo || "",
            PuestoSolicita: datosGenerales.PuestoSolicita || "",
            LugarNacimiento: datosGenerales.LugarNacimiento || "",
            FechaNacimiento: datosGenerales.FechaNacimiento || "",
            EstadoCivil: datosGenerales.EstadoCivil || "",
            TipoSangre: datosGenerales.TipoSangre || "",
            IHSS: datosGenerales.IHSS || "",
            TelefonoFijo: datosGenerales.TelefonoFijo || "",
            Direccion: datosGenerales.Direccion || "",
            CasaPropia: datosGenerales.CasaPropia|| "",
            Correo: datosGenerales.CorreoElectronico || "",
            Celular: datosGenerales.Celular || "",
            ValorCasa: datosGenerales.ValorCasa || "",
            AlquilerCasa: datosGenerales.AlquilerCasa || "",
            CuantosTrabajan: datosGenerales.CuantosTrabajan || "",
            PoseeVehiculo: datosGenerales.PoseeVehiculo || "",
            TipoVehiculo: datosGenerales.TipoVehiculo || "",
            MarcaVehiculo: datosGenerales.MarcaVehiculo || "",
            PlacaVehiculo: datosGenerales.PlacaVehiculo || "",
            AnioVehiculo: datosGenerales.AnioVehiculo || "",
            TipoLicencia: datosGenerales.TipoLicencia || "",
            VencimientoLicencia: datosGenerales.VencimientoLicencia || "",
            OtroVehiculo: datosGenerales.OtroVehiculo || "",
            Salud: datosGenerales.Salud || "",
            BebidasAlcoholicas: datosGenerales.BebidasAlcoholicas || "",
            BebidasFrecuencia: datosGenerales.BebidasFrecuencia || "",
            Conyugue: datosGenerales.Conyugue || "",
            Hijos: datosGenerales.Hijos || "",
            CTelefonoFijo: datosGenerales.CTelefonoFijo || "",
            CCelular: datosGenerales.CCelular || "",
            CCorreoElectronico: datosGenerales.CCorreoElectronico || "",

            // Nivel Académico
            Profesion: nivelAcademico.Profesion || "",
            Escuela: nivelAcademico.Escuela || "",
            EscuelaGrado: nivelAcademico.EscuelaGrado || "",
            Instituto: nivelAcademico.Instituto || "",
            InstitutoGrado: nivelAcademico.InstitutoGrado || "",
            TituloSecundaria: nivelAcademico.TituloSecundaria || "",
            Universidad: nivelAcademico.Universidad || "",
            TituloUniversidad: nivelAcademico.TituloUniversidad || "",
            Cursos: nivelAcademico.Cursos || "",
            Equipos: nivelAcademico.Equipos || "",
            ServicioMilitar: nivelAcademico.ServicioMilitar || "",
            UnidadSM: nivelAcademico.UnidadSM || "",
            LugarSM: nivelAcademico.LugarSM || "",
            PeriodoSM: nivelAcademico.PeriodoSM || "",
            ComandanteSM: nivelAcademico.ComandanteSM || "",
            RangoSM: nivelAcademico.RangoSM || "",
            RegistroSM: nivelAcademico.RegistroSM || "",

            // Empleos Anteriores
            NegocioPropio: empleosAnteriores.NegocioPropio || "",
            NombreE1: empleosAnteriores.NombreE1 || "",
            TelefonoE1: empleosAnteriores.TelefonoE1 || "",
            SueldoIE1: empleosAnteriores.SueldoIE1 || "",
            SueldoFE1: empleosAnteriores.SueldoFE1 || "",
            FechaIE1: empleosAnteriores.FechaIE1 || "",
            PuestoE1: empleosAnteriores.PuestoE1 || "",
            ObligacionesE1: empleosAnteriores.ObligacionesE1 || "",
            NombreE2: empleosAnteriores.NombreE2 || "",
            TelefonoE2: empleosAnteriores.TelefonoE2 || "",
            SueldoIE2: empleosAnteriores.SueldoIE2 || "",
            SueldoFE2: empleosAnteriores.SueldoFE2 || "",
            FechaIE2: empleosAnteriores.FechaIE2 || "",
            PuestoE2: empleosAnteriores.PuestoE2 || "",
            ObligacionesE2: empleosAnteriores.ObligacionesE2 || "",
            NombreE3: empleosAnteriores.NombreE3 || "",
            TelefonoE3: empleosAnteriores.TelefonoE3 || "",
            SueldoIE3: empleosAnteriores.SueldoIE3 || "",
            SueldoFE3: empleosAnteriores.SueldoFE3 || "",
            FechaIE3: empleosAnteriores.FechaIE3 || "",
            PuestoE3: empleosAnteriores.PuestoE3 || "",
            ObligacionesE3: empleosAnteriores.ObligacionesE3 || "",

            // Habilidades
            Habilidad1: habilidades.Habilidad1 || "",
            Habilidad2: habilidades.Habilidad2 || "",

            // Personas que Conocen
            NombreP1: personasConocen.NombreP1 || "",
            TelefonoP1: personasConocen.TelefonoP1 || "",
            DireccionP1: personasConocen.DireccionP1 || "",
            NombreP2: personasConocen.NombreP2 || "",
            TelefonoP2: personasConocen.TelefonoP2 || "",
            DireccionP2: personasConocen.DireccionP2 || "",
            NombreP3: personasConocen.NombreP3 || "",
            TelefonoP3: personasConocen.TelefonoP3 || "",
            DireccionP3: personasConocen.DireccionP3 || "",

            // Familiares
            NombreF1: familiares.NombreF1 || "",
            TelefonoF1: familiares.TelefonoF1 || "",
            DireccionF1: familiares.DireccionF1 || "",
            NombreF2: familiares.NombreF2 || "",
            TelefonoF2: familiares.TelefonoF2 || "",
            DireccionF2: familiares.DireccionF2 || "",
            NombreF3: familiares.NombreF3 || "",
            TelefonoF3: familiares.TelefonoF3 || "",
            DireccionF3: familiares.DireccionF3 || "",
          };
        });

        // Generar el libro de Excel
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

        // Convertir el libro a un archivo Blob
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

        // Crear un enlace de descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "solicitudes_empleo.xlsx";
        link.click();

        console.log("¡Archivo Excel generado exitosamente!");

        await new Promise(resolve => setTimeout(resolve, 2000)); // Simula un retraso de 2 segundos

        this.success = true;
      } catch (error) {
        console.error("Error al obtener datos de la API o generar el Excel:", error);
      }finally {
        this.loading = false;
      }
    },

  },
};
</script>

<style scoped>
.card-header .col-12, .card-header .col-md-4 {
  padding: 5px;
}
#btnFormularioInscripcion {
  width: 100vh;
}
#barraBusqueda {
  background-color: #f0f0f0; /* Cambia el color de fondo */
  color: #495057; /* Cambia el color del texto */
  border-color: #ced4da; /* Cambia el color del borde si es necesario */
  border-radius: 0.25rem; /* Ajusta el redondeo del borde si es necesario */
  margin-bottom: 20px;
}
#barraBusqueda:focus {
  background-color: #fff; /* Cambia el color de fondo */
  color: #495057; /* Cambia el color del texto */
  border-color: #80bdff; /* Cambia el color del borde si es necesario */
  outline: 0; /* Quita el resplandor azul */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Agrega un resplandor azul si es necesario */
  margin-bottom: 20px;
}
input::placeholder {
  color: #495057; /* Cambia el color del texto del marcador de posición */
  opacity: 2; /* Agrega opacidad al marcador de posición */
}
</style>
