import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Membresia from "@/pages/Membresias.vue";
import Matrimonio from "@/pages/Matrimonios.vue";
import SolicitudEmpleo from "@/pages/SolicitudEmpleo.vue";
import InscripcionMembresias from '../pages/InscripcionMembresias.vue';
import Login from "@/layout/dashboard/Login.vue";
import EditarMembresias from '../pages/EditarMembresias.vue';
import InscripcionMatrimonios from '../pages/InscripcionMatrimonios.vue';
import EditarMatrimonio from '../pages/EditarMatrimonio.vue';
import InscripcionSolicitudEmpleo from '../pages/InscripcionSolicitudEmpleo.vue';
import EditarSolicitudEmpleo from '../pages/EditarSolicitudEmpleo.vue';
import Descargas from '../pages/Descargas.vue';

Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: to => {
      const loggedIn = sessionStorage.getItem('jwt');
      return loggedIn ? '/dashboard' : '/login';
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "membresias",
        name: "Miembros con Membresia",
        component: Membresia,
        meta: { requiresAuth: true },
      },
      {
        path: 'inscripcion-membresia',
        name: 'Inscripcion Membresias',
        component: InscripcionMembresias,
        meta: { requiresAuth: true },
      },
      {
        path: 'editar-membresia',
        name: 'Editar Membresias',
        component: EditarMembresias,
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "matrimonios",
        name: "Miembros en Matrimonio",
        component: Matrimonio,
        meta: { requiresAuth: true },
      },
      {
        path: 'inscripcion-matrimonio',
        name: 'Inscripcion Matrimonios',
        component: InscripcionMatrimonios,
        meta: { requiresAuth: true },
      },
      {
        path: "editar-matrimonio",
        name: "Editar Matrimonios",
        component: EditarMatrimonio,
        meta: { requiresAuth: true },
      },

      {
        path: "solicitud-empleo",
        name: "Miembros con Solicitud de Empleo",
        component: SolicitudEmpleo,
        meta: { requiresAuth: true },
      },
      {
        path:"inscripcion-solicitud-empleo",
        name:"Inscripcion Solicitud de Empleo",
        component: InscripcionSolicitudEmpleo,
        meta: { requiresAuth: true },
      },
      {
        path: "editar-solicitud-empleo",
        name: "Editar Solicitud de Empleo",
        component: EditarSolicitudEmpleo,
        meta: { requiresAuth: true },
      },
      {
        path: "descargas",
        name: "Descargas",
        component: Descargas,
        meta: { requiresAuth: true },
      },

    ],
  },
  { path: "*", component: NotFound },
];

const router = new Router({
  mode: 'history',
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem('jwt');

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;

