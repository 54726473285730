<template>
  <div class="row">
    <div class="col-12">
      <card-formularios :title="table.title" :subTitle="table.subTitle">
        <template #raw-content>
          <!-- Contenedor de búsqueda y paginación -->
          <div class="table-controls d-flex justify-between mb-4 col-md-4">
            <!-- Campo de búsqueda -->
            <v-text-field
              v-model="searchQuery"
              label="Buscar registro"
              class="search-bar"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </div>

          <!-- Tabla de Vuetify -->
          <v-data-table
            :headers="table.columns"
            :items="table.data"
            :search="searchQuery"
            item-value="id"
            class="elevation-1"
            dense
            :items-per-page="10"
            :loading="isLoading"
            loading-text="Cargando registros..."
            no-data-text="No hay datos disponibles"
            no-results-text="No se encontraron resultados"
            :footer-props="{
              'items-per-page-text': 'Registros por página:',
              'items-per-page-options': [5, 10, 15, 20],
              'page-text': '{0}-{1} de {2}'
            }"
          ></v-data-table>

        </template>
      </card-formularios>
    </div>
  </div>
</template>


<script>
import CardFormularios from "@/components/Cards/CardDescargaSolicitudEmpleo.vue";
import solicitudEmpleoService from "@/services/solicitud_empleo_service";

export default {
  components: {
    CardFormularios,
  },
  data() {
    return {
      isLoading: false, // Estado de carga
      searchQuery: "", // Texto de búsqueda
      table: {
        title: "Solicitudes de Empleo",
        subTitle: "Lista de las solicitudes de empleo registradas",
        columns: [
          { text: "ID", value: "id", align: "center" },
          { text: "Identidad", value: "Identidad", align: "center" },
          { text: "Nombre", value: "Nombre", align: "left" },
          { text: "Zona", value: "ZonaMic", align: "center" },
          { text: "Departamento", value: "Departamento", align: "center" },
          { text: "Municipio", value: "Municipio", align: "center" },
          { text: "Localidad", value: "Localidad", align: "center" },
        ],
        data: [], // Datos de la tabla
      },
    };
  },
  methods: {
    async fetchSolicitudEmpleo() {
      try {
        this.isLoading = true; // Mostrar animación de carga
        const response = await solicitudEmpleoService.fetchSolicitudEmpleo();
        const dataArray = response.data.data;

        this.table.data = dataArray.map((item) => {
          const datosGenerales = item.attributes?.DatosGenerales || {};
          return {
            id: item.id || "",
            Identidad: typeof datosGenerales.Identidad === 'object' ? "" : datosGenerales.Identidad || "",
            Nombre: typeof datosGenerales.Nombre === 'object' ? "" : datosGenerales.Nombre || "",
            ZonaMic: typeof datosGenerales.ZonaMic === 'object' ? "" : datosGenerales.ZonaMic || "",
            Departamento: typeof datosGenerales.Departamento === 'object' ? "" : datosGenerales.Departamento || "",
            Municipio: typeof datosGenerales.Municipio === 'object' ? "" : datosGenerales.Municipio || "",
            Localidad: typeof datosGenerales.Localidad === 'object' ? "" : datosGenerales.Localidad || "",
          };
        });
      } catch (error) {
        console.error("Error al obtener los datos de la API:", error);
      } finally {
        this.isLoading = false; // Ocultar animación de carga
      }
    },
  },
  mounted() {
    this.fetchSolicitudEmpleo();
  },
};
</script>


<style lang="scss" scoped>
::v-deep .v-data-table {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  // Estilo para el header
  .v-data-table-header {
    background-color: #f8fafc !important;
    border-bottom: 2px solid #e2e8f0;

    th {
      font-weight: 600 !important;
      color: #1a202c !important;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 16px !important;
      text-align: center !important; // Centrar headers
    }
  }

  // Estilo para las filas
  .v-data-table__wrapper {
    tbody {
      tr {
        border-bottom: 1px solid #f0f2f5;

        &:nth-child(even) {
          background-color: #fafafa;
        }

        &:hover {
          background-color: #f1f5f9 !important;
          transition: background-color 0.2s ease;
        }

        td {
          padding: 12px 16px !important;
          color: #374151;
          font-size: 0.875rem;
          line-height: 1.5;

          // Centrar todos los campos excepto el nombre
          &:not(:nth-child(3)) {
            text-align: center !important;
          }

          // El campo nombre alineado a la izquierda
          &:nth-child(3) {
            text-align: left !important;
          }
        }
      }
    }
  }

  // Estilo para el footer
  .v-data-footer {
    border-top: 1px solid #e2e8f0;
    background-color: #f8fafc;
    padding: 12px 16px;
    font-size: 0.875rem;

    .v-data-footer__select {
      margin: 0 16px;
    }

    .v-data-footer__pagination {
      .v-btn {
        min-width: 32px;
        margin: 0 4px;
      }
    }
  }
}

// Estilo para el campo de búsqueda
::v-deep .v-text-field {
  .v-input__slot {
    background-color: #f8fafc !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 8px !important;

    &:hover {
      border-color: #cbd5e1 !important;
      background-color: #f1f5f9 !important;
    }

    &:focus-within {
      border-color: #3b82f6 !important;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
    }
  }
}
</style>






