import apiClient from './api';

export default {
  fetchMatrimonios() {
    return apiClient.get('/matrimonios?populate[0]=DatosGenerales&populate[1]=DatosGenerales.Departamento&populate[2]=DatosGenerales.Municipio&populate[3]=OtrosDatos');
  },

  fecthMatrimoniosID(){
    return apiClient.get('/matrimonios');
  },


  // Metodos GET para cargar los datos en los combobox en Marimonios
  getCiudades() {
    return apiClient.get('/municipios');
  },
  getDepartamentos() {
    return apiClient.get('/departamentos');
  },

  // Metodos POST para guardar los datos de Matrimonios
  postMatrimonio(data) {
    return apiClient.post('/matrimonios', { data }).then(response => response.data);;
  },

  //Metodos PUT para actualizar los datos de Matrimonios
  putMatrimonio(id, data) {
    return apiClient.put(`/matrimonios/${id}`, { data });
  },
}


